@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@font-face {
  font-family: trippysans;
  src: url(./assets/TrippySans.woff);
}
@font-face {
  font-family: manuka;
  src: url(./assets/Manuka_Med.otf);
}
@font-face {
  font-family: financier;
  src: url(./assets/Financier_Reg.otf);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2ea;
  color: #333333

 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Inter',
    monospace;
}
