body {
  overflow-x: hidden;
  height: 100%;
}

.bodyContainer {
  margin-bottom: 100px;
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 10px;
  align-items: center;
  overflow: hidden;
  position:relative;
  font-family: "manuka";
  font-size: 400%;
  font-weight: 700;
  line-height: 200%;
  letter-spacing: .05em;

}

.intro_hello {
  position: absolute;
  pointer-events: none;
}

.center_content {
  align-items: center;
  text-align: center;
}



.svgAnimation {
  width: 100px;
  height: 120px;
  display: flex;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  margin-right: -10px;
  margin-left: -7px;
}

.item {
  width: 56%;
  overflow: visible;
  stroke: #000000;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.typewriter {
  font-size: 30px;
}

.cursor {
  font-size: 30px;
  animation-name: blink;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

@keyframes blink {
  from { opacity: 1; }
  to { opacity: 0; }
}


.profilePicture {
  border-radius: 50%;
  width: 180px;
}

.bodyTitle {
  font-family: 'financier';
  font-size:xx-large;
}

.smallBodyTitle {
  font-family: 'financier';
  font-size:x-large;
}

.bodyText {
  text-align: left;
}

.footer {
  align-items: center;
  flex-direction: column;
  height: 100px;
  margin-top: auto;
}


.container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  
  flex-grow: 1;
}

.section {
  flex: 1;
  max-width: 100%;
  margin: 15px;
  padding: 5px;
  border-radius: 8px;
  font-size:small;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .section {
    max-width: 100%; /* Adjust max-width for stacked sections */
  }
}





span.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}
@keyframes wave-animation {
  0% {transform: rotate( 0.0deg)}
  10% {transform: rotate(-10.0deg)}
  20% {transform: rotate( 12.0deg)}
  30% {transform: rotate(-10.0deg)}
  40% {transform: rotate( 9.0deg)}
  50% {transform: rotate( 0.0deg)}
  100% {transform: rotate( 0.0deg)}
}


 .bodyContainer {
  animation: fadein 0.6s;
  -moz-animation: fadein 0.6s; /* Firefox */
  -webkit-animation: fadein 0.6s; /* Safari and Chrome */
  -o-animation: fadein 0.6s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}
