.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
  
}

.navigationMenu {
  font-size: 25px;
  font-family: 'Inter';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:link {
  text-decoration: none;
  color: #b96032

}

a:visited {
  text-decoration: none;
  color: #b96032;
}

.home {
  text-align: center;
}